export function lvrSummary(fundingSumm, securityValue) {
  const LVR = 100 * (fundingSumm / securityValue) || 0;

  const isFinite = Boolean(Number.isFinite(LVR));

  return isFinite ? LVR.toFixed(2) : 0;
}

export function UMISummary(incomes, repayments, expenses) {
  const incomesData = incomes || 0;
  const repaymentsData = repayments || 0;
  const expensesData = expenses || 0;

  const actualUMI = Math.round(incomesData - repaymentsData - expensesData);

  return actualUMI;
}

export function mortgageRepaymentSummary(payments, mortgages) {
  let payment = payments;

  mortgages.forEach((item) => {
    const stressed = parseInt(item, 10);

    payment += stressed;
  });

  return Math.round(payment);
}
