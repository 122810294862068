import {
  loanRepayment,
} from '@/services/finance';

export default function getSressedMonthRepayment(term, value, stressRate, now = new Date()) {
  const presentValue = parseInt(value, 10);

  // Monthly rate
  const ratePercent = parseFloat(stressRate) / 100 / 12;

  const endTerm = new Date(term);

  const presentValueIsLessThanZero = Boolean(presentValue <= 0);
  const presentValueIsNaN = Boolean(Number.isNaN(presentValue));
  const ratePercentIsNaN = Boolean(Number.isNaN(ratePercent));
  const endTermIsLessThanNow = Boolean(endTerm <= now);

  if (presentValueIsLessThanZero || presentValueIsNaN || ratePercentIsNaN || endTermIsLessThanNow) {
    return 0;
  }

  const periods = Math.floor((endTerm.getDate() - now.getDate()) / 30 + endTerm.getMonth()
  - now.getMonth() + (12 * (endTerm.getFullYear() - now.getFullYear())));

  const paymentPerPeriod = loanRepayment(presentValue, periods, ratePercent);

  const paymentFromatted = Math.round(paymentPerPeriod);

  return paymentFromatted;
}
