import {
  adjustToMonth,
  loanRepayment,
  nzAccLevy,
  nzNetIncome,
} from '@/services/finance';

export function workNet(workGross) {
  const { net } = nzNetIncome(workGross * 12);

  const accLevy = nzAccLevy(workGross * 12);

  const workNetResult = Math.round((net - accLevy) / 12);

  return workNetResult;
}

export function groupByApplicant(work) {
  const refsIdArray = work.map((item) => item.ref.id);

  const makeUniqArray = (array) => {
    const uniqSet = new Set(array);
    return [...uniqSet];
  };

  const refsUnicIdArray = makeUniqArray(refsIdArray);

  const applicantsGroups = [];

  refsUnicIdArray.forEach((id) => {
    const filteredRefs = work.filter((item) => item.ref.id === id);
    applicantsGroups.push(filteredRefs);
  });

  return applicantsGroups;
}

export function getTotalByIncome(income) {
  const isIncomeEmpty = Boolean(income?.length === 0);

  if (isIncomeEmpty) {
    return 0;
  }

  const reducer = (acc, current) => {
    const incomeVal = parseInt(current.income, 10);
    const adjustedIncome = incomeVal * adjustToMonth(current.income_freq);
    return acc + (adjustedIncome || 0);
  };

  return income?.reduce(reducer, 0);
}

export function liabilitiesTotal(liabilitiesData) {
  let total = 0;

  if (liabilitiesData) {
    const filteredData = liabilitiesData.filter((item) => {
      const isNoDebt = Boolean(item?.debt === 'No');
      const isPeriod = Boolean(item?.repayment_period);

      if (isNoDebt && isPeriod) {
        return true;
      }

      return false;
    });

    filteredData.forEach((item) => {
      const value = parseInt(item.repayment_amount, 10) || 0;
      const adjust = value * adjustToMonth(item?.repayment_period);

      total += adjust;
    });
  }

  return Math.round(total);
}

export function repaymentTopUp(rate, topUpData) {
  const ratePercent = parseFloat(rate) / 100 / 12;
  let total = 0;
  let paymentPerPeriod = 0;

  const rateIsNaN = Boolean(Number.isNaN(ratePercent));

  if (topUpData && !rateIsNaN) {
    topUpData.forEach((item) => {
      const termToNum = parseInt(item?.term, 10);
      const termIsNaN = Boolean(Number.isNaN(termToNum));

      const amountToNum = parseInt(item?.amount, 10);
      const amountIsNaN = Boolean(Number.isNaN(amountToNum));

      if (!termIsNaN && !amountIsNaN) {
        const term = item?.term;
        const value = item?.amount;
        const presentValue = value ? parseInt(value, 10) : 0;

        if (term.length) {
          paymentPerPeriod = loanRepayment(presentValue, term * 12, ratePercent);
        }

        total += paymentPerPeriod;
      }
    });
  }

  return total;
}
