export const PAYMENT_PER_PERIOD_TYPE = {
  end: 0,
  begin: 1,
};

/** Calculate Payment Per Period (PMT)
 *
 * @param ir {number} - interest rate per month
 * @param np {number} - number of periods (months years)
 * @param pv {number} - present value
 * @param fv {number} - future value
 * @param type {number} - when the payments are due:
 * 0: end of the period, e.g. end of month (default)
 * 1: beginning of period
 * @returns {number}
 */
export function PMT(ir, np, pv, fv, type) {
  let pmt;

  if (ir === 0) {
    return -(pv + fv) / np;
  }

  const pvif = (1 + ir) ** np;
  pmt = -ir * ((pv * pvif + fv) / (pvif - 1));

  if (type === 1) {
    pmt /= (1 + ir);
  }

  return pmt;
}

/** Calculate NZ Net income based on Gross income.
 * @param {number} grossIncome - Gross income.
 * @returns {{tax: number, net: number}}
 * */

export function nzNetIncome(grossIncome) {
  let restAmount = grossIncome;
  const taxes = [
    { from: 0, to: 14000, tax: 0.105 },
    { from: 14000, to: 48000, tax: 0.175 },
    { from: 48000, to: 70000, tax: 0.3 },
    { from: 70000, to: 180000, tax: 0.33 },
    { from: 180000, to: Infinity, tax: 0.39 },
  ];
  const result = {
    net: 0,
    tax: 0,
  };

  taxes.forEach((taxRate) => {
    let baseAmount = taxRate.to - taxRate.from;
    if (restAmount < baseAmount) {
      baseAmount = restAmount;
    }
    result.tax += baseAmount * taxRate.tax;
    result.net += baseAmount - baseAmount * taxRate.tax;
    restAmount -= baseAmount;
  });

  return result;
}

/** Calculate New Zealand ACC Levy deduction according
 * {@link https://www.ird.govt.nz/income-tax/income-tax-for-individuals/acc-clients-and-carers/acc-earners-levy-rates|IRD}
 *
 * @param grossIncome {number} - Gross Income
 * @return number
 */
export function nzAccLevy(grossIncome) {
  if (grossIncome < 0) {
    return 0;
  }
  const levyRate = 0.0139;
  const maximumLevyPayable = 1819.66;
  const result = grossIncome * levyRate;

  return result < maximumLevyPayable ? result : maximumLevyPayable;
}

/**
 * Adjust amount to month.
 * @example Adjusting $100 'per week' to 'per month'
 * 100 * adjustToMonth('Month') // return 433
 * @example Adjusting $90000 'per year' to 'per month'
 * 90000 * adjustToMonth('Year') // return 7500
 * @param period
 * @return {number}
 */
export function adjustToMonth(period) {
  if (period === 'Week') return 52 / 12;
  if (period === 'Fortnight') return 26 / 12;
  if (period === 'Month') return 1;
  if (period === 'Year') return 1 / 12;
  return 0;
}

/**
 * The same as {@link adjustToMonth `adjustToMonth()`} but adjust to Year
 * @param period
 * @return {number}
 */
export function adjustToYear(period) {
  if (period === 'Week') return 52;
  if (period === 'Fortnight') return 26;
  if (period === 'Month') return 12;
  if (period === 'Year') return 1;
  return 0;
}

/**
 * Calculate loan repayment
 * @param loanAmount {number} - Required amount
 * @param periods {number} - Number of periods (months)
 * @param ratePerPeriod {number} - Rate per period (6% per year = 0.5% per month!)
 * @return {number}
 */

export function loanRepayment(loanAmount, periods, ratePerPeriod) {
  // loan amount/(((1+nr)^360-1)/(nr*(1+nr)^360))

  if (Number.isNaN(loanAmount) || loanAmount === 0) {
    return 0;
  }

  if (Number.isNaN(periods) || periods === 0) {
    return 0;
  }

  if (ratePerPeriod === 0) {
    return loanAmount / periods;
  }
  const a = (1 + ratePerPeriod) ** periods - 1;
  const b = ratePerPeriod * (1 + ratePerPeriod) ** periods;
  return loanAmount / (a / b);
}
