import { mapGetters } from 'vuex';
import {
  adjustToMonth,
  loanRepayment,
} from '@/services/finance';

import {
  liabilitiesTotal,
  repaymentTopUp,
  getTotalByIncome,
  workNet,
  groupByApplicant,
} from '@/services/applicationComputed';

import getSressedMonthRepayment from './mortgagesStressRepayment';

import {
  lvrSummary,
  UMISummary,
} from './summary';

export default {
  computed: {
    ...mapGetters(['APPLICATION']),

    creditCardsRepayment() {
      const defaultCreditRepayment = 0;

      const { credit_cards: cards } = this.APPLICATION?.data;

      const isCardNotExist = Boolean(cards?.length === 0);
      if (isCardNotExist) {
        return defaultCreditRepayment;
      }

      // 3% - Credit cards monthly repayment
      const monthlyRepayment = 0.03;

      const reducer = (acc, card) => {
        const cardLimitDefaultValue = 0;

        const cardLimit = card?.limit ?? cardLimitDefaultValue;

        return acc + parseInt(cardLimit, 10);
      };

      const creditRepayment = monthlyRepayment * cards.reduce(reducer, 0);

      return creditRepayment;
    },

    incomes() {
      const rental = this.APPLICATION?.data?.incomes?.rental;
      const work = this.APPLICATION?.data?.incomes?.employee;
      const other = this.APPLICATION?.data?.incomes?.other;

      return {
        rental,
        work,
        other,

        get rentalTotal() {
          const isRentalEmpty = Boolean(this.rental?.length === 0);

          if (isRentalEmpty) {
            return 0;
          }

          const reducer = (acc, current) => {
            const income = parseInt(current?.income, 10);
            const adjustedIncome = income * adjustToMonth(current?.income_freq);
            return acc + (adjustedIncome || 0);
          };

          return this.rental.reduce(reducer, 0);
        },

        get rentalDiscountedTotal() {
          const isRentalEmpty = Boolean(this.rental?.length === 0);

          if (isRentalEmpty) {
            return 0;
          }

          const reducer = (acc, current) => {
            const income = parseInt(current.income, 10);
            const discount = parseInt(current.discount, 10) / 100;

            const adjustedIncome = income * adjustToMonth(current.income_freq);
            return acc + (adjustedIncome || 0) * (discount || 1);
          };

          return this.rental?.reduce(reducer, 0);
        },

        get otherTotal() {
          return getTotalByIncome(this.other);
        },

        get workGross() {
          const workGrossArray = [];

          const groupedWork = groupByApplicant(this.work);

          groupedWork.forEach((group) => {
            let summary = 0;

            group.forEach((item) => {
              const incomeItem = [];

              incomeItem.push(item);

              summary += getTotalByIncome(incomeItem);
            });

            workGrossArray.push(summary);
          });

          return workGrossArray;
        },

        get workNet() {
          let total = 0;

          this.workGross.forEach((item) => {
            total += workNet(item);
          });

          return total;
        },

        get netGrandTotal() {
          const netGrandTotal = this.workNet + this.rentalDiscountedTotal + this.otherTotal;

          return Number.isNaN(netGrandTotal) ? 0 : netGrandTotal;
        },
      };
    },

    depositValue() {
      const defaultDepositValue = 0;

      const depositSourceValue = this.APPLICATION?.data?.funding_details?.deposit_source;

      const isDepositSourceNotExist = Boolean(!depositSourceValue);
      if (isDepositSourceNotExist) {
        return defaultDepositValue;
      }

      const { deposit_source: depositSource } = this.APPLICATION?.data?.funding_details;

      const reducer = (previewValue, currentValue) => {
        const parseIntRadix = 10;
        const amount = currentValue?.amount ?? 0;

        const value = parseInt(previewValue, parseIntRadix) + parseInt(amount, parseIntRadix);

        return value;
      };

      const depositValue = depositSource.reduce(reducer, 0);

      return depositValue;
    },

    paymentPerPeriod() {
      const paymentPerPeriod = this.getPaymentPerPeriod('rate');

      return paymentPerPeriod;
    },

    stressPaymentPerPeriod() {
      const stressPaymentPerPeriod = this.getPaymentPerPeriod('stress_rate');

      return stressPaymentPerPeriod;
    },

    proposedHousePrice() {
      const esimatedPriceValue = this.APPLICATION?.data?.funding_details?.new_purchase[0]
        .estimated_price;

      const isEstimatedPriceNotExist = Boolean(!esimatedPriceValue);
      if (isEstimatedPriceNotExist) {
        return 0;
      }

      const { estimated_price: estimatedPrice } = this.APPLICATION.data.funding_details;

      return estimatedPrice;
    },

    existingHouses() {
      const isRealEstateAssetsNotExist = Boolean(!this.APPLICATION?.data?.real_estate_assets);

      if (isRealEstateAssetsNotExist) {
        return [];
      }

      const { real_estate_assets: realEstateAssets } = this.APPLICATION?.data;

      const filterEstateAssetsBySecurity = (estateAsset) => {
        const isEstateAssetHasSecurity = Boolean(estateAsset?.security.toLowerCase() === 'yes');

        return isEstateAssetHasSecurity;
      };

      const filteredRealEstateAssets = realEstateAssets?.filter(filterEstateAssetsBySecurity);

      return filteredRealEstateAssets;
    },

    securityValue() {
      const isExistingHousesIsEmpty = Boolean(this.existingHouses?.length === 0);

      const needReturnDefaultValue = Boolean(isExistingHousesIsEmpty);
      if (needReturnDefaultValue) {
        return this.estimatedPriceValue;
      }

      const reducer = (prevValue, currValue) => prevValue + parseInt(currValue.value, 10);

      const reduceValue = parseInt(this.estimatedPriceValue, 10);
      const initReducerValue = Number.isNaN(reduceValue) ? 0 : reduceValue;

      const securityValue = this.existingHouses.reduce(reducer, initReducerValue);

      return Number.isNaN(securityValue) ? 0 : securityValue;
    },

    propertyValue() {
      return this.securityValue.toLocaleString();
    },

    livingExpensesData() {
      const result = {
        expenses: [],
        total: 0,
      };

      const expenses = this.APPLICATION?.data?.living_expenses;

      const isExpensesIsEmpty = Boolean(expenses?.length === 0);
      if (isExpensesIsEmpty) {
        return result;
      }

      const fillResult = (expensesItem) => {
        const recordMonthly = parseInt(expensesItem.amount, 10)
        * adjustToMonth(expensesItem.period);

        const isRecordNaN = Boolean(Number.isNaN(recordMonthly));

        const recordMonthlyValue = isRecordNaN ? 0 : recordMonthly;

        const record = {
          description: expensesItem.description,
          monthly: recordMonthlyValue,
          amount: expensesItem.amount,
          period: expensesItem.period,
        };
        result.expenses.push(record);
        result.total += record.monthly;
      };

      expenses?.forEach(fillResult);

      return result;
    },

    liabilities() {
      const result = {
        liabilities: [],
        total: 0,
      };

      const liabilities = this.APPLICATION?.data?.liabilities;

      const isLiabilitiesNotExist = Boolean(liabilities?.items?.liabilities === 0);
      if (isLiabilitiesNotExist) {
        return result;
      }

      liabilities?.items?.forEach((liability) => {
        const recordMonthly = parseInt(liability.repayment_amount, 10)
        * adjustToMonth(liability.repayment_period);

        const record = {
          description: liability.type,
          monthly: recordMonthly,
          amount: liability.repayment_amount,
          balance: liability.balance,
          period: liability.repayment_period,
        };

        result.liabilities.push(record);

        result.total += record.monthly;
      });

      return result;
    },

    estimatedPriceValue() {
      let amount = 0;

      const data = this.APPLICATION?.data?.funding_details;

      amount = this.$options.filters.valueToNum(data?.new_purchase[0].estimated_price);

      return amount;
    },

    estimatedConstructValue() {
      let amount = 0;

      const data = this.APPLICATION?.data?.funding_details;

      const landValue = this.$options.filters.valueToNum(data?.new_purchase[0].land_value);
      amount += landValue;

      const costs = this.$options.filters.valueToNum(data?.new_purchase[0].costs);
      amount += costs;

      return amount;
    },

    fundingSumm() {
      const status = this.APPLICATION?.data?.funding_details?.new_purchase?.[0]?.property_status;

      const data = this.APPLICATION?.data?.funding_details;

      let summary = 0;

      if (status === 'construction') {
        summary += this.estimatedConstructValue;
      } else {
        summary += this.estimatedPriceValue;
      }

      const debtToNum = this.$options.filters.textFormatter(this.getLiabilitiesSummary);
      const debtAmount = parseInt(debtToNum, 10);
      summary += debtAmount;

      summary += this.refinanceTotal;

      summary += this.topUpTotal;

      const loanData = data?.loan?.[0]?.amount || 0;
      const loanAmount = parseInt(loanData, 10);
      summary += loanAmount;

      if (data?.deposit_source) {
        data?.deposit_source.forEach((deposit) => {
          const amount = parseInt(deposit?.amount, 10);

          summary -= amount;
        });
      }

      return summary;
    },

    lendingRequired() {
      const lendingRequired = this.fundingSumm.toLocaleString();

      return lendingRequired;
    },

    deposit() {
      const deposit = parseInt(this.depositValue, 10).toLocaleString();

      return deposit;
    },

    LVR() {
      const mortgages = this.APPLICATION?.data?.liabilities?.mortgages;

      const sameBankItems = mortgages.filter((item) => item.refianance === 'The same bank');

      let sameBankFunds = 0;

      sameBankItems.forEach((item) => {
        sameBankFunds += parseInt(item.balance, 10);
      });

      const summary = this.fundingSumm + sameBankFunds;

      const LVR = lvrSummary(summary, this.securityValue);

      return LVR;
    },

    expensesTotal() {
      const expenses = this.APPLICATION?.data?.living_expenses;

      if (expenses) {
        const formattedExpenses = expenses.filter((item) => item?.period && item?.amount);

        let total = 0;

        if (formattedExpenses.length === 0) {
          return total;
        }

        formattedExpenses.forEach((item) => {
          const period = item?.period;
          const amount = item?.amount;

          total += amount * adjustToMonth(period);
        });

        const totalFromatted = Math.round(total);

        return totalFromatted;
      }

      return 0;
    },

    totalMonthlyIncome() {
      const totalMonthlyIncome = Math.round(this.incomes.netGrandTotal || 0).toLocaleString();

      return totalMonthlyIncome;
    },

    totalMonthlyExpensesAndCommitments() {
      const totalMonthlyExpensesAndCommitments = Math.round(
        this.liabilities.total + this.livingExpensesData.total,
      ).toLocaleString();

      return totalMonthlyExpensesAndCommitments;
    },

    actualUncommittedMonthlyIncome() {
      const UMI = UMISummary(this.incomes.netGrandTotal,
        this.totalActualRepayments, this.expensesTotal);

      return UMI.toLocaleString();
    },

    UMI() {
      const UMI = UMISummary(this.incomes.netGrandTotal,
        this.totalStressRepayments, this.expensesTotal);

      return UMI.toLocaleString();
    },

    getLiabilitiesSummary() {
      const liabilitiesData = this.APPLICATION?.data?.liabilities?.items;

      let total = 0;

      if (liabilitiesData) {
        const isDebtLiabilities = liabilitiesData.filter((item) => item?.debt === 'Yes');

        isDebtLiabilities.forEach((item) => {
          total += parseInt(item.balance, 10);
        });
      }

      const cardsData = this.APPLICATION?.data?.liabilities?.credit_cards;

      if (cardsData) {
        const isDebtCards = cardsData.filter((item) => item?.debt === 'Yes');

        isDebtCards.forEach((item) => {
          total += parseInt(item.limit, 10);
        });
      }

      const totalFormatted = this.$options.filters.integerFormatter(total.toString());

      return totalFormatted;
    },

    liabilitiesTotal() {
      const liabilitiesData = this.APPLICATION?.data?.liabilities?.items;
      const total = liabilitiesTotal(liabilitiesData);
      return this.$options.filters.integerFormatter(total.toString());
    },

    actualMortgagesTotal() {
      const mortgages = this.APPLICATION?.data?.liabilities?.mortgages;

      const stressRate = this.APPLICATION.data.lender.rate;

      let total = 0;

      if (mortgages) {
        const filteredData = mortgages.filter((item) => item?.refianance !== 'Refinance');

        filteredData.forEach((item) => {
          const term = item.terms;
          const value = parseInt(item.balance, 10);

          const actual = Math.round(getSressedMonthRepayment(term, value, stressRate));

          total += actual;
        });
      }

      const finalTotal = Number.isNaN(total) ? 0 : total;

      return finalTotal;
    },

    stressedMortgagesTotal() {
      const mortgages = this.APPLICATION?.data?.liabilities?.mortgages;

      let total = 0;

      if (mortgages) {
        const filteredData = mortgages.filter((item) => item?.refianance !== 'Refinance');

        filteredData.forEach((item) => {
          total += parseInt(item.stressed, 10);
        });
      }
      const finalTotal = Number.isNaN(total) ? 0 : total;

      return finalTotal;
    },

    creditCardsTotal() {
      const cards = this.APPLICATION?.data?.liabilities?.credit_cards;

      let total = 0;

      if (cards) {
        const filteredData = cards.filter((item) => item.debt === 'No');

        filteredData.forEach((item) => {
          total += parseInt(item.repayment, 10);
        });
      }

      const finalTotal = Number.isNaN(total) ? 0 : total;

      return finalTotal;
    },

    topUpTotal() {
      const data = this.APPLICATION?.data?.funding_details?.topUp || 0;

      let total = 0;

      if (data) {
        data.forEach((item) => {
          total += parseInt(item.amount, 10);
        });
      }

      return total;
    },

    refinanceTotal() {
      const mortgages = this.APPLICATION?.data?.liabilities?.mortgages;

      let total = 0;

      if (mortgages) {
        const refinance = mortgages.filter((item) => item?.refianance === 'Refinance');

        refinance.forEach((item) => {
          total += parseInt(item?.balance, 10);
        });
      }

      return total;
    },

    debtsTotal() {
      const amount = this.$options.filters.textFormatter(this.getLiabilitiesSummary);

      const adjustment = this.APPLICATION?.data?.funding_details?.debt?.[0]?.adjustment || 0;

      const total = parseInt(amount, 10) + parseInt(adjustment, 10);

      return total;
    },

    actualMonthlyRepayments() {
      const total = parseInt(this.actualMortgagesTotal, 10)
        + parseInt(this.creditCardsTotal, 10)
        + parseInt(this.liabilitiesTotal, 10);

      return total;
    },

    stressedMonthlyRepayments() {
      const total = parseInt(this.stressedMortgagesTotal, 10)
        + parseInt(this.creditCardsTotal, 10)
        + parseInt(this.liabilitiesTotal, 10);

      return total;
    },

    totalActualRepayments() {
      const total = this.actualMonthlyRepayments
        + this.stressedRepaymentNewPurchase()
        + this.stressedRepaymentConsDebt()
        + this.stressedRefinance()
        + this.stressedTopUp()
        + this.stressedBusinessLoan();

      return total;
    },

    totalStressRepayments() {
      const total = this.stressedMonthlyRepayments
        + this.stressedRepaymentNewPurchase(true)
        + this.stressedRepaymentConsDebt(true)
        + this.stressedRefinance(true)
        + this.stressedTopUp(true)
        + this.stressedBusinessLoan(true);

      return total;
    },

    loanFundingStressTotals() {
      const total = this.stressedRepaymentNewPurchase(true)
        + this.stressedRepaymentConsDebt(true)
        + this.stressedRefinance(true)
        + this.stressedTopUp(true)
        + this.stressedBusinessLoan(true);

      return total;
    },
  },

  methods: {
    getPaymentPerPeriod(rateType = 'rate') {
      const { [rateType]: rate } = this.APPLICATION?.data?.lender;
      const estimatedPrice = this.estimatedPriceValue;

      const term = this.APPLICATION?.data?.funding_details?.new_purchase?.[0]?.terms;

      const ratePercent = parseFloat(rate) / 100 / 12;
      const termInt = parseInt(term, 10);

      const presentValue = parseInt(estimatedPrice, 10);

      const paymentPerPeriod = loanRepayment(presentValue, termInt * 12, ratePercent);

      const paymentFromatted = Math.round(paymentPerPeriod);

      return paymentFromatted;
    },

    stressedRepaymentNewPurchase(stress = false) {
      const term = this.APPLICATION?.data?.funding_details?.new_purchase?.[0]?.terms;

      if (!term) {
        return 0;
      }
      const status = this.APPLICATION?.data?.funding_details?.new_purchase?.[0]?.property_status;

      let rate = '';

      if (stress) {
        rate = this.APPLICATION?.data?.lender?.stress_rate;
      } else {
        rate = this.APPLICATION?.data?.lender?.rate;
      }

      const ratePercent = parseFloat(rate) / 100 / 12;
      const value = status === 'construction' ? this.estimatedConstructValue : this.estimatedPriceValue;

      const diff = (parseInt(value, 10) - parseInt(this.depositValue, 10));

      const presentValue = value ? diff : 0;

      const paymentPerPeriod = loanRepayment(presentValue, term * 12, ratePercent);

      const total = Math.round(paymentPerPeriod);

      const finalTotal = Number.isNaN(total) ? 0 : total;

      return finalTotal;
    },

    stressedRepaymentConsDebt(stress = false) {
      const term = this.APPLICATION?.data?.funding_details?.debt?.[0]?.term;

      let rate = '';

      if (stress) {
        rate = this.APPLICATION?.data?.lender?.stress_rate;
      } else {
        rate = this.APPLICATION?.data?.lender?.rate;
      }

      const ratePercent = parseFloat(rate) / 100 / 12;
      const value = this.debtsTotal;
      const presentValue = value ? parseInt(value, 10) : 0;

      const paymentPerPeriod = loanRepayment(presentValue, term * 12, ratePercent);

      const total = Math.round(paymentPerPeriod);

      return total;
    },

    stressedRefinance(stress = false) {
      let rate = '';

      if (stress) {
        rate = this.APPLICATION?.data?.lender?.stress_rate;
      } else {
        rate = this.APPLICATION?.data?.lender.rate;
      }

      const ratePercent = parseFloat(rate) / 100 / 12;

      const mortgages = this.APPLICATION?.data?.liabilities?.mortgages;

      let total = 0;

      if (mortgages) {
        const refinance = mortgages.filter((item) => item?.refianance === 'Refinance');

        refinance.forEach((item) => {
          if (item?.term) {
            const term = item?.term * 12;
            const value = item?.balance;
            const presentValue = value ? parseInt(value, 10) : 0;

            const paymentPerPeriod = loanRepayment(presentValue, term, ratePercent);

            total += Math.round(paymentPerPeriod);
          }
        });
      }

      return total;
    },

    stressedTopUp(stress = false) {
      const topUpData = this.APPLICATION?.data?.funding_details?.topUp || 0;

      let rate = '';

      if (stress) {
        rate = this.APPLICATION?.data?.lender?.stress_rate;
      } else {
        rate = this.APPLICATION?.data?.lender?.rate;
      }

      return repaymentTopUp(rate, topUpData);
    },

    stressedBusinessLoan(stress = false) {
      const data = this.APPLICATION?.data?.funding_details?.loan?.[0];

      let rate = '';

      if (stress) {
        rate = this.APPLICATION?.data?.lender?.stress_rate;
      } else {
        rate = this.APPLICATION?.data?.lender?.rate;
      }

      const ratePercent = parseFloat(rate) / 100 / 12;
      const term = data?.terms;
      const value = data?.amount;
      const presentValue = value ? parseInt(value, 10) : 0;

      const paymentPerPeriod = loanRepayment(presentValue, term * 12, ratePercent);

      const total = Math.round(paymentPerPeriod);

      return total;
    },
  },
};
